@mixin flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin flex-start {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

@mixin flex-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.family-card {
  width: 100%;
  height: fit-content;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  border-radius: 8px;
  margin-left: 2rem;
  padding: 10px;
}

.family-card-title-container {
  @include flex-between;
}

.family-title {
  font-size: 16px;
  font-weight: bold;
}