@mixin flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin flex-column-between {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.main-form {
  &-container {
    width: 100%;
    height: 100vh;
    background-color: #F2F2F2;
    position: relative;
    @include flex-column-between;
  }
  &-items-wrapper {
    background-color: #ffffff;
    width: 60%;
    height: 80%;
    margin-top: 2.5rem;
    box-shadow: rgba(100, 100, 111, 0.2) 0 7px 29px 0;
    @include flex-column-between;
    flex-direction: column;
    border-radius: 8px;
    position: relative;
  }
  &-progress {
    text-align: center;
    //position: absolute;
    //top: 40px;
    width: 75%;
    margin-top: 20px;
    @include flex-center;
  }
  &-question {
    width: 75%;
    height: 100%;
    margin: 0 auto;
    @include flex-center;
    flex-direction: column;
    .question-text {
      direction: rtl;
      align-self: end;
      margin-bottom: 10px;
      font-weight: 600;
    }
  }
  &-footer {
  background-color: #fff;
    display: flex;
    align-items: center;
    direction: rtl;
    width: 100%;
    height: 60px;
    .question-handler-container {
      width: 10%;
      display: flex;
      align-items: center;
      margin-right: 10%;
      cursor: pointer;
      .next-question {
        width: 100px;
        height: 35px;
        background-color: #55D1DD;
        color: #fff;
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
        @include flex-center;
        transition: background-color .5s;
        //&:hover {
        //  background-color: #1c8aff;
        //}
      }
      .prev-question {
        width: 100px;
        height: 35px;
        background-color: #7DE3ED;
        color: #fff;
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
        @include flex-center;
        transition: background-color .5s;
        //&:hover{
        //  background-color: #58b0ff;
        //}
      }
    }
    .footer-question {
      margin-right: 3rem;
      color: #adadad;
      width: 55%;
    }
}
}

progress[value] {
  --color: yellow; /* the progress color */
  --background: #f5f5f5; /* the background color */
  direction: rtl;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  width: 100%;
  height: 18px;
  margin: 10px 10px 0;
  border-radius: 10em;
  background: var(--background);
  padding: 3px;
}
progress[value]::-webkit-progress-bar {
  border-radius: 10em;
  background: var(--background);
}
progress[value]::-webkit-progress-value {
  border-radius: 10em;
  background: var(--color);
  background-image: linear-gradient(to right, #FFC13F , #FFD885);
}
progress[value]::-moz-progress-bar {
  border-radius: 10em;
  background: var(--color);
  background-image: linear-gradient(to right, red , yellow);
}