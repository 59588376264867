.buy-service {
  &-container {
    direction: rtl;
    width: 60%;
    margin: 0 auto;
    padding: 3% 0;
    text-align: right;
    //display: flex;
    //flex-direction: column;
    //align-items: center;
    //justify-content: center;
  }
  &-title {
    font-weight: 600;
    color: #32B8C5;
    margin-bottom: 1.5rem;
  }
}