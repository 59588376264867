@mixin flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.start-page{
  &-container {
    @include flex-center;
    width: 100%;
    height: 100vh;
    text-align: center;
  }
  &-title {
    font-size: 40px;
    font-weight: 600;
    margin-bottom: 2rem;
  }
}
